import { Button, Grid } from '@material-ui/core';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CustomSnackbar } from '../../components/CustomSnackbar';
import { EmptyMessage } from '../../components/EmptyMessage';
import { ErrorMessage } from '../../components/ErrorMessage';
import { CsvFile } from '../../components/Icons/CsvFile';
import { LinkCard } from '../../components/LinkCard';
import { Loading } from '../../components/Loading';
import { ScreenTitle } from '../../components/ScreenTitle';
import { DeleteDialog } from '../../components/dialogs/DeleteDialog';
import values from '../../data/values';
import { fetchAllUsers } from '../../store/action_creators/collaborators.actions';
import { deleteLink, fetchLinks, fetchOneUseLinks } from '../../store/action_creators/links.actions';
import { LinkTypeEnum, Status } from '../../store/config/enums';
import { Link, RootState } from '../../store/config/types';
import { initialState as initialLinks } from '../../store/reducers/links';
import LinksFilters from './LinksFilters';
import OneUseLinksFilters from './OneUseLinksFilters';

interface LinksProps {
  linkType: LinkTypeEnum;
}

function Links({ linkType }: LinksProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { auth, links, collaborators } = useSelector((state: RootState) => state);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [deletingLink, setDeletingLink] = useState<boolean>(false);
  const [linkToDelete, setLinkToDelete] = useState<Link | null>(null);
  const businessId = auth.account?.business?.id ?? -1;
  const isOneTimeLink = linkType === LinkTypeEnum.ONETIME;

  const title = isOneTimeLink ? 'Links de 1 uso' : 'Links multiuso';
  const tooltip = isOneTimeLink
    ? 'Permite crear un link de pago para solo un usuario que podrá ser usado una única vez.'
    : 'Permite crear links de uso recurrente que podrán ser usados más de una vez y por distintos usuarios.';

  const linksToDisplay = isOneTimeLink ? links.oneUseLinks : links.links;
  const currentPage = isOneTimeLink ? links.oneUseCurrentPage : links.currentPage;
  const pageCount = isOneTimeLink ? links.oneUsePageCount : links.pageCount;
  const loading = isOneTimeLink ? links.loadingOneUseLinks : links.loadingLinks;
  const errorMessage = isOneTimeLink ? links.oneUseLinksErrorMessage : links.linksErrorMessage;

  const oneUseLinkCount = links.oneUseLinks?.length ?? 'Cargando';
  const linkCount = links.links?.length ?? 'Cargando';

  useEffect(() => {
    if (
      auth.account &&
      !collaborators.loadingCollaborators &&
      collaborators.collaborators === null &&
      collaborators.collaboratorsErrorMessage === null
    ) {
      dispatch(fetchAllUsers(businessId));
    }
  }, [
    auth.account,
    collaborators.loadingCollaborators,
    collaborators.collaborators,
    collaborators.collaboratorsErrorMessage,
    businessId,
    dispatch,
  ]);

  useEffect(() => {
    if (auth.account) {
      if (isOneTimeLink && !links.oneUseLinks && !links.loadingOneUseLinks) {
        dispatch(
          fetchOneUseLinks(
            initialLinks.oneUseLinksFilters,
            1,
            values.linksPageLimit,
            businessId!,
            linkType,
            true,
          ),
        );
      } else if (linkType === LinkTypeEnum.PERMANENT && !links.links && !links.loadingLinks) {
        dispatch(
          fetchLinks(initialLinks.linksFilters, 1, values.linksPageLimit, businessId!, linkType, true),
        );
      }
    }
  }, [
    links.loadingLinks,
    links.links,
    links.linksType,
    links.linksFilters,
    auth.account,
    businessId,
    dispatch,
    linkType,
    links.oneUseLinks,
    links.loadingOneUseLinks,
    isOneTimeLink,
  ]);

  const beginDeleteLink = (link: Link) => {
    setDeleteOpen(true);
    setLinkToDelete(link);
  };

  const confirmDeleteLink = () => {
    setDeletingLink(true);

    if (businessId && linkToDelete) {
      dispatch(deleteLink({ businessId: businessId.toString(), linkId: linkToDelete.id }));
    }
  };

  const closeDeleteSnack = () => {
    setDeletingLink(false);

    if (links.deleteLinkSuccess) setDeleteOpen(false);
  };

  const loadMore = () => {
    if (isOneTimeLink) {
      dispatch(
        fetchOneUseLinks(
          links.oneUseLinksFilters,
          links.oneUseCurrentPage + 1,
          values.linksPageLimit,
          businessId!,
          linkType,
          false,
        ),
      );
    } else {
      dispatch(
        fetchLinks(
          links.linksFilters,
          links.currentPage + 1,
          values.linksPageLimit,
          businessId!,
          linkType,
          false,
        ),
      );
    }
  };

  return (
    <div className="screen-container links">
      <div className="screen-header">
        <div className="screen-title">
          <ScreenTitle
            title={title}
            elementsAmount={isOneTimeLink ? oneUseLinkCount : linkCount}
            tooltip={tooltip}
          />
        </div>
        {isOneTimeLink && (
          <Button
            startIcon={<CsvFile />}
            variant="outlined"
            color="secondary"
            onClick={() => navigate('/upload-csv-links')}
            disableElevation
          >
            Crear con CSV
          </Button>
        )}
        <Button
          className="create-button"
          variant="contained"
          color="primary"
          onClick={() => navigate(`/${isOneTimeLink ? 'one-use' : 'permanent'}-link`)}
          disableElevation
        >
          Crear link
        </Button>
      </div>
      {isOneTimeLink ? (
        <OneUseLinksFilters businessId={businessId} linkType={LinkTypeEnum.ONETIME} />
      ) : (
        <LinksFilters businessId={businessId} linkType={LinkTypeEnum.PERMANENT} />
      )}
      {linksToDisplay ? (
        linksToDisplay.length > 0 ? (
          <InfiniteScroll
            className="infinite"
            hasMore={currentPage < pageCount}
            next={loadMore}
            dataLength={linksToDisplay ? linksToDisplay.length : 0}
            loader={
              links.loadingLinks && (
                <div className="loading-div">
                  <Loading />
                </div>
              )
            }
          >
            <Grid container spacing={2} className="links-list">
              {linksToDisplay.map((link) => (
                <Grid item xs={12} sm={6} key={link.id}>
                  <LinkCard link={link} setLinkToDelete={beginDeleteLink} />
                </Grid>
              ))}
            </Grid>
          </InfiniteScroll>
        ) : (
          <EmptyMessage message="No se encontraron links para los filtros aplicados" />
        )
      ) : loading ? (
        <Loading />
      ) : (
        errorMessage && <ErrorMessage message="Ha ocurrido un error al obtener los links" />
      )}
      <DeleteDialog
        message="¿Está seguro de que desea eliminar el link?"
        open={deleteOpen}
        setOpen={setDeleteOpen}
        deleteAction={confirmDeleteLink}
        deleteDisabled={deletingLink}
      />
      <CustomSnackbar
        open={deletingLink && (links.deleteLinkSuccess || links.deleteLinkErrorMessage !== null)}
        message={
          links.deleteLinkSuccess ? 'Se borró el link correctamente' : 'Ocurrió un error al borrar el link'
        }
        handleClose={closeDeleteSnack}
        type={links.deleteLinkSuccess ? Status.SUCCESS : Status.ERROR}
      />
    </div>
  );
}

export default Links;
