import { Box, Button, Card, createStyles, Link, makeStyles, Theme, Typography } from '@material-ui/core';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UploadIcon from '../../assets/UploadIcon';
import CheckCircleIcon from '../../components/Icons/CheckCircleIcon';
import { CsvFile } from '../../components/Icons/CsvFile';
import { ScreenTitle } from '../../components/ScreenTitle';
import styles from '../../styles/_variables.module.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    uploadCard: {
      marginTop: '1.25rem',
      padding: '1.5625rem 1.25rem',
    },
    title: {
      fontWeight: +styles.semiBoldTextWeight,
      color: styles.slateBlue,
      fontSize: '1.0625rem',
      marginBottom: '1.1875rem',
    },
    uploadContainer: {
      display: 'flex',
      alignItems: 'end',
      justifyContent: 'space-between',
      gap: '1.1875rem',
    },
    fileContainer: {
      width: '80%',
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      border: `1px dashed ${styles.borderButtonSecondary}`,
      '& p': {
        marginTop: '0.9375rem',
      },
    },
    icon: {
      width: '2.1875rem',
      height: '2.1875rem',
    },
    helperText: {
      color: styles.slateBlue,
      fontSize: '0.75rem',
      width: '32%',
      textAlign: 'center',
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: 'auto',
    },
    download: {
      marginTop: '1.125rem',
      cursor: 'pointer',
      color: styles.primary,
      textDecoration: 'underline',
    },
  }),
);

function UploadCSVLinks() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.size > 10 * 1024 * 1024) {
        alert('El archivo no debe superar los 10 MB.');
        return;
      }
      setSelectedFile(file);
    }
  };

  const handleUploadClick = () => {
    document.getElementById('csvFileInput')?.click();
  };

  const handleContinue = () => {
    console.log('Archivo elegido:', selectedFile);
  };

  const handleCancel = () => {
    setSelectedFile(null);
    navigate(-1);
  };

  return (
    <div className="screen-container">
      <ScreenTitle title={'Crear links con CSV'}>
        <Button onClick={handleCancel} variant="outlined" color="secondary">
          Cancelar
        </Button>
        <Button onClick={handleContinue} variant="contained" color="primary" disabled={!selectedFile}>
          Continuar
        </Button>
      </ScreenTitle>
      <Card className={classes.uploadCard}>
        <Typography className={classes.title}>Archivo con Links</Typography>
        <Box className={classes.uploadContainer}>
          <Box className={classes.fileContainer}>
            {selectedFile ? (
              <CheckCircleIcon className={classes.icon} />
            ) : (
              <CsvFile className={classes.icon} />
            )}
            <Typography className={classes.helperText}>
              {selectedFile
                ? `El archivo ${selectedFile.name} ha sido cargado correctamente`
                : `El archivo debe estar en formato CSV y no superar los 10 MB de tamaño.`}
            </Typography>
          </Box>
          <Box className={classes.buttonContainer}>
            <Button
              onClick={handleUploadClick}
              variant="outlined"
              color="secondary"
              startIcon={<UploadIcon />}
            >
              <input
                id="csvFileInput"
                type="file"
                accept=".csv"
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
              Cargar CSV
            </Button>
            <Link href="/examples/links.csv" download="ejemplo-links.csv" className={classes.download}>
              Descargar csv de ejemplo
            </Link>
          </Box>
        </Box>
      </Card>
    </div>
  );
}

export default UploadCSVLinks;
