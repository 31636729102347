import { SVGProps } from 'react';
import variables from '../../styles/_variables.module.scss';

function CheckCircleIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.625 -0.000244141C8.5483 -0.000244141 6.51823 0.61557 4.79152 1.76933C3.0648 2.92308 1.71899 4.56296 0.92427 6.48158C0.129549 8.4002 -0.0783855 10.5114 0.326759 12.5482C0.731904 14.585 1.73193 16.4559 3.20038 17.9244C4.66883 19.3928 6.53976 20.3929 8.57656 20.798C10.6134 21.2031 12.7246 20.9952 14.6432 20.2005C16.5618 19.4058 18.2017 18.06 19.3554 16.3332C20.5092 14.6065 21.125 12.5765 21.125 10.4998C21.125 7.71498 20.0188 5.04427 18.0496 3.07513C16.0805 1.106 13.4098 -0.000244141 10.625 -0.000244141ZM10.625 19.6448C8.81629 19.6448 7.0482 19.1084 5.54432 18.1035C4.04043 17.0987 2.86829 15.6704 2.17613 13.9994C1.48396 12.3284 1.30286 10.4896 1.65572 8.71565C2.00859 6.9417 2.87956 5.31222 4.15851 4.03326C5.43746 2.75431 7.06695 1.88334 8.8409 1.53047C10.6149 1.17761 12.4536 1.35871 14.1246 2.05088C15.7957 2.74304 17.2239 3.91518 18.2288 5.41907C19.2337 6.92295 19.77 8.69105 19.77 10.4998C19.77 12.9252 18.8065 15.2512 17.0915 16.9662C15.3765 18.6813 13.0504 19.6448 10.625 19.6448ZM16.625 7.99976L8.984 15.5798C8.88875 15.6749 8.75963 15.7283 8.625 15.7283C8.49038 15.7283 8.36126 15.6749 8.26601 15.5798L4.625 11.9148C4.52986 11.8195 4.47643 11.6904 4.47643 11.5558C4.47643 11.4211 4.52986 11.292 4.625 11.1968L4.986 10.8388C5.08126 10.7436 5.21038 10.6902 5.34501 10.6902C5.47963 10.6902 5.60875 10.7436 5.70401 10.8388L8.62401 13.7828L15.544 6.91876C15.6393 6.82362 15.7684 6.77018 15.903 6.77018C16.0376 6.77018 16.1668 6.82362 16.262 6.91876L16.62 7.27976C16.6677 7.32663 16.7058 7.38247 16.7319 7.44407C16.758 7.50567 16.7716 7.57182 16.7721 7.63872C16.7726 7.70562 16.7598 7.77195 16.7346 7.83391C16.7093 7.89587 16.6721 7.95223 16.625 7.99976Z"
        fill={variables.lightGreen}
      />
    </svg>
  );
}

export default CheckCircleIcon;
